@import "src/scss/module";

.widget {
  // position: fixed;
  width: 100%;
  transition: 0.5s;
  transform: translateY(0%);
  margin-top: -50px;
  position: relative;
  z-index: 1;
  opacity: 0;

  &[class*=loading] {
    margin-top: 0px;
  }

  &[class*="visible"] {
    opacity: 1;
  }

  &[class*="visible"] &__form {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(30px);
  }

  &__form {
    position: relative;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(0px);
    border-radius: 4px;
    transition: 0.5s backdrop-filter, 1s opacity, 3s box-shadow;
    padding: 1em;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      max-width: 66vw;
    }

    @include media-breakpoint-up(md) {
      margin: 0;
    }

    label {
      display: block;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }
    }

    input {
      width: 100%;
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      border: 0;
      padding: 0.5em 0;
      font-weight: 500;
      font-size: 1.1rem;

      &::placeholder {
        color: #787878;
      }
    }

    :global {
      .dropdown {

        &-toggle {
          border-radius: 4px;
          background-color: #f4faff !important;
          color: $dark !important;
          border: none;

          &::after {
            border-top-color: $primary;
          }
        }

        &-menu {
          border-radius: 4px;
          background-color: #f4faff;
          border: none;
          font-weight: 500;
          margin-top: -5px;
        }

        &-item {
          font-weight: 500;
          transition: .3s;

          &:hover {
            color: $primary;
            background-color: transparent;
          }
        }
      }
    }

    button {
      border-radius: 4px;

      &:hover {
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }

  &__links  {
    position: relative;
    padding: .7rem;
    font-size: 1rem;
    display: inline-flex;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0 0 0.5rem 0.5rem;
    white-space: nowrap;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      border-radius: 0 0.5rem 0.5rem 0.5rem;
    }

    @include media-breakpoint-between(lg, xl) {
      max-width: 64vw;
    }

    @include media-breakpoint-up(md) {
      margin: 0 0.5rem;
      padding: 0.7rem 1rem;
    }

    a[role=button] {
      font-size: .75rem;
      white-space: nowrap;
    }
  }

  &[class*="hide"] {
    transform: translateY(100%) !important;
  }
}